<template>
  <div class="menuItem" v-if="!item.meta.hidden">
    <template v-if="hasChildren(item)">
      <el-menu-item :index="resolvePath(showItem.path)"
        ><e-icon :icon-name="showItem.meta.icon"></e-icon
        ><span>{{ showItem.meta.name }}</span>
      </el-menu-item>
    </template>
    <el-submenu
      popper-append-to-body
      :index="resolvePath(item.path)"
      :key="item.id"
      v-else
    >
      <template slot="title">
        <e-icon :icon-name="item.meta.icon"></e-icon>
        <span>{{ item.meta.name }}</span>
      </template>
      <menu-item
        v-for="child in item.children"
        :base-path="resolvePath(item.path)"
        :key="child.path"
        :item="child"
      />
    </el-submenu>
  </div>
</template>

<script>
import MenuItem from "@/layout/components/Menu/cpns/MenuItem";
import { EIcon } from "e-icon-picker";
export default {
  name: "menuItem",
  components: { MenuItem, EIcon },
  created() {},
  computed: {},
  props: {
    item: {
      type: Object,
    },
    basePath: {
      type: String,
    },
  },
  data() {
    return {
      showItem: null,
    };
  },
  methods: {
    hasChildren(item) {
      if (!item.children) {
        item.children = [];
      }
      /**
       * 判断子元素隐藏的个数
       */
      const filterChildren = item.children.filter((item) => {
        if (item.meta.hidden) {
          return false;
        }
        return true;
      });
      if (filterChildren.length == 0 && item.meta.menuType != "D") {
        this.showItem = item;
        return true;
      }
      return false;
    },
    resolvePath(routerPath) {
      if (this.basePath) {
        return this.basePath + "/" + routerPath;
      }
      return routerPath;
    },
  },
};
</script>

<style lang="scss" >
.el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none !important;
}
</style>
