const getters = {
  siderbarRouters: (state) => state.user.siderbarRouters,
  token: (state) => state.user.token,
  roles: (state) => state.user.roles,
  permissions: (state) => state.user.permissions,
  nickname: (state) => state.user.nickname,
  avatar: (state) => state.user.avatar,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  dict: state => state.dict.dict,
  id: (state) => state.user.id,
};
export default getters;
