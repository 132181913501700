<template>
  <div class="upload-file">
    <el-upload
      multiple
      :action="uploadFileUrl"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-success="handleUploadSuccess"
      :show-file-list="false"
      :headers="headers"
      :disabled="disabled"
      class="upload-file-uploader"
      ref="fileUpload"
      :on-preview="onPreview"
    >
      <!-- 上传按钮 -->
      <el-button v-if="!disabled" size="mini" type="primary"
        >选取文件</el-button
      >
      <el-button v-if="disabled && !value" type="text" size="mini"
        >暂未上传附件</el-button
      >
      <!-- 上传提示 -->
      <div class="el-upload__tip" slot="tip" v-if="showTip && !disabled">
        请上传
        <template v-if="fileSize">
          大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
        </template>
        <template v-if="fileType">
          格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b>
        </template>
        的文件
      </div>
    </el-upload>

    <!-- 文件列表 -->
    <transition-group
      class="upload-file-list el-upload-list el-upload-list--text"
      name="el-fade-in-linear"
      tag="ul"
    >
      <li
        :key="file.url"
        class="el-upload-list__item ele-upload-list__item-content"
        v-for="(file, index) in fileList"
      >
        <el-link :underline="false" @click="onPreview(index, 'view')">
          <span class="el-icon-document"> {{ getFileName(file.name) }} </span>
        </el-link>
        <div class="ele-upload-list__item-content-action">
          <el-link
            v-if="!disabled"
            :underline="false"
            @click="handleDelete(index)"
            type="danger"
            >删除</el-link
          >
          <!-- <el-link
            type="primary"
            :underline="false"
            @click="onPreview(index, 'view')"
            >预览</el-link
          > -->
          <el-link
            type="danger"
            :underline="false"
            @click="onPreview(index, '')"
            >下载</el-link
          >
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
export default {
  name: "FileUpload",
  props: {
    // 值
    value: [String, Object, Array],
    // 数量限制
    limit: {
      type: Number,
      default: 5,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["doc", "xls", "xlsx", "ppt", "txt", "pdf", "docx"],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      number: 0,
      uploadList: [],
      uploadFileUrl:
        process.env.VUE_APP_BASE_URL + "/oss/upload?SA-TOKEN=" + getToken(), // 上传文件服务器地址
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      src: "",
      fileTitle: "",
      fileList: [],
    };
  },
  watch: {
    value: {
      async handler(val) {
        if (val) {
          let temp = 1;
          // 首先将值转为数组
          let list;
          // if (Array.isArray(val)) {
          //   list = val;
          // } else {
          //   await listByIds(val).then((res) => {
          //     list = res.data.map((oss) => {
          //       oss = {
          //         name: oss.originalName,
          //         url: oss.url,
          //         ossId: oss.ossId,
          //       };
          //       return oss;
          //     });
          //   });
          // }
          this.fileList = [
            {
              name: "文件1",
              url: val,
            },
          ];
          // 然后将数组转为对象数组
          // this.fileList = list.map((item) => {
          //   item = { name: item.name, url: item.url, ossId: item.ossId };
          //   item.uid = item.uid || new Date().getTime() + temp++;
          //   return item;
          // });
        } else {
          this.fileList = [];
          return [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },
  methods: {
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        const fileName = file.name.split(".");
        const fileExt = fileName[fileName.length - 1];
        const isTypeOk = this.fileType.indexOf(fileExt) >= 0;
        if (!isTypeOk) {
          this.$modal.msgError(
            `文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$modal.msgError(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      this.$modal.loading("正在上传文件，请稍候...");
      this.number++;
      return true;
    },
    // 文件个数超出
    handleExceed() {
      this.$modal.msgError(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    // 上传失败
    handleUploadError(err) {
      this.$modal.msgError("上传文件失败，请重试");
      this.$modal.closeLoading();
    },
    // 上传成功回调
    handleUploadSuccess(res, file) {
      if (res.code === 200) {
        this.uploadList.push({
          name: file.name,
          url: res.data,
          ossId: "1111",
        });
        this.uploadedSuccessfully();
      } else {
        this.number--;
        this.$modal.closeLoading();
        this.$modal.msgError(res.msg);
        this.$refs.fileUpload.handleRemove(file);
        this.uploadedSuccessfully();
      }
    },
    // 删除文件
    handleDelete(index) {
      let ossId = this.fileList[index].ossId;
      // delOss(ossId);
      this.fileList.splice(index, 1);
      this.$emit("input", this.listToString(this.fileList));
    },
    // 上传结束处理
    uploadedSuccessfully() {
      if (this.number > 0 && this.uploadList.length === this.number) {
        this.fileList = this.fileList.concat(this.uploadList);
        this.uploadList = [];
        this.number = 0;
        this.$emit("input", this.listToString(this.fileList));
        this.$modal.closeLoading();
      }
    },
    // 预览
    onPreview(index, type) {
      const file = this.fileList[index];
      this.fileTitle = file.name;
      if (type == "view") {
      } else {
        this.downloadFile("/" + file.url, file.name);
      }
    },
    // 获取文件名称
    getFileName(name) {
      if (name.lastIndexOf("/") > -1) {
        return name.slice(name.lastIndexOf("/") + 1);
      } else {
        return name;
      }
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        strs += list[i].url + separator;
      }
      return strs != "" ? strs.substr(0, strs.length - 1) : "";
    },
  },
};
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}
</style>
